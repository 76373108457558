import useFormatDate from './useFormatDate';
import useFormatMoney from './useFormatMoney';

export default function useFilters(value: string, fnName: string): string | number | undefined {
  switch (fnName) {
    case 'useFormatMoney':
      return useFormatMoney(parseFloat(value));
    case 'useFormatDate':
      return useFormatDate(value);
    default:
      return value;
  }
}
